<template>
	<Layout class="main">
		<Header class="header">
			<div class="header__bd">
				<div class="logo__panel">
					<a class="logo-box" @click="gotoHome">
						<img class="logo"
							:src="systemSetting && systemSetting.logo ? 'https://api.ganleizhong.com/'+systemSetting.logo : logo" />
						<div class="title">{{ systemSetting && systemSetting.title ? systemSetting.title : '类众' }}</div>
					</a>
					<div class="city">{{ city }}</div>
					<a class="a-btn" @click="onChangeCity" style="margin-left:10px">切换城市</a>
				</div>
				<Menu class="menu" mode="horizontal" theme="light" :active-name="activeMemu" @on-select="onSelectMemu"
					style="margin-top: 3px;">
					<MenuItem name="home">首页</MenuItem>
					<!-- <MenuItem name="edit-info">编辑</MenuItem> -->
					<MenuItem name="demand">供需</MenuItem>
					<MenuItem name="publicity">公示</MenuItem>
					<MenuItem name="template">做资料</MenuItem>
					<MenuItem name="data">共享资料</MenuItem>
				</Menu>
				<div class="account__panel">
					<template v-if="userInfo.id">
						<a class="a-btn" style="margin-right: 15px" @click="onBuyVip(userInfo.vip_info.level)">VIP</a>
						<a class="name-box" @click="onUserCenter">
							<Avatar class="u-avatar" shape="square" size="50" icon="ios-person" :src="userAvatar" />
							<!-- <img v-if="userInfo.avatar_url !== null && userInfo.avatar_url !== ''" class="avatar" :src="userInfo.avatar_url"/>
              <Icon v-else :size="24" class="name-icon" type="ios-person" color="white"></Icon> -->
							<div class="name">{{userInfo.real_name || userInfo.username}}</div>
						</a>
						<a class="a-btn" style="margin-left: 15px" @click="onLogout">退出</a>
					</template>
					<template v-else>
						<a class="a-btn" @click="onLogin">登录</a>
						<a class="a-btn" @click="onRegister" style="margin-left:10px">注册</a>
					</template>
				</div>
			</div>
		</Header>
		<Content class="content-wrapper">
			<keep-alive :include="cacheList">
				<router-view />
			</keep-alive>
		</Content>
		<Login :shown="showLogin" />
		<Register :shown="showRegister" />
		<Forgot :shown="showForgot" />
		<div class="pendant">
			<Poptip trigger="hover" placement="left">
				<div class="item">
					<img src="@/assets/images/icon_call.png" />
					<div class="label">客服</div>
				</div>
				<div class="poptip__bd" slot="content">服务时间：{{serviceTime}}</div>
				<div class="poptip__bd" slot="content">联系电话：{{contactPhone}}</div>
				<div class="poptip__bd" slot="content">QQ号：{{qq}}</div>
				<div class="poptip__bd" slot="content" style="display: flex; align-items: flex-start;">微信：<img
						class="logo" :src="wechatQRCode" style="width: 200px;" /></div>
			</Poptip>
		</div>
	</Layout>
</template>

<script>
	import logo from '@/assets/logo.jpg'
	import Login from './Login.vue'
	import Forgot from './Forgot.vue'
	import Register from './Register.vue'
	import {
		mapGetters,
		mapMutations,
		mapActions
	} from 'vuex'
	import {
		getHomeData
	} from '@/api/home'
	import {
		getSystemSetting
	} from '@/api/setting'

	export default {
		name: 'layout',
		components: {
			Login,
			Register,
			Forgot
		},
		data() {
			return {
				// nopic: require('@/assets/images/nopic.png'),
				cacheList: [],
				logo,

				// 客服信息
				serviceTime: null,
				contactPhone: null,
				qq: null,
				wechatQRCode: null,

				systemSetting: null,
			}
		},
		computed: {
			...mapGetters(['userAvatar', 'userInfo', 'showLogin', 'showRegister', 'showForgot', 'cityId', 'city',
				'homeData'
			]),
			activeMemu() {
				return this.$route.name
			}
		},
		watch: {
			$route(newRoute, oldRoute) {
				// console.log(`Main newRoute: ${newRoute.name} oldRoute: ${oldRoute.name}`)
				// console.log('Main newRoute: ', newRoute)
				// console.log('Main oldRoute: ', oldRoute)
			}
		},
		mounted() {
			this.initData()
		},
		methods: {
			async initData() {
				await this.getSystemSetting()
				try {
					const res = await getHomeData()
					this.serviceTime = res.content.service_time
					this.contactPhone = res.content.contact_phone
					this.qq = res.content.qq
					this.wechatQRCode = res.content.wechat_qrcode
				} catch (error) {
					// this.$Message.error(error.message)
				}
			},
			...mapMutations(['showLoginDialog', 'showRegisterDialog', 'showForgotDialog']),
			...mapActions(['handleLogout']),
			async getSystemSetting() {
				const res = await getSystemSetting()
				this.systemSetting = res.content
			},
			gotoHome() {
				this.$router.push({
					name: 'home'
				})
			},
			onChangeCity() {
				this.$router.push({
					name: 'changecity',
					params: {
						backPage: this.$route.name
					}
				})
			},
			onUserCenter() {
				this.$router.push({
					name: 'user'
				})
			},
			onLogin() {
				this.showLoginDialog()
			},
			onLogout() {
				this.handleLogout({
					route: this.$route
				}).then(res => {
					this.$Message.success('退出成功')
				}).catch((err) => {
					this.$Message.error(err.message)
				})
			},
			onRegister() {
				this.showRegisterDialog()
			},
			onForgot() {
				this.showForgotDialog()
			},
			onSelectMemu(name) {
				this.$router.push({
					name
				})
			},
			onBuyVip(level) {
				this.$router.push({
					name: 'member',
					params: {
						level
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.header {
		position: fixed;
		width: 100%;
		top: 0;
		right: 0;
		background-color: #4183c4;
		padding: 0;
		z-index: 100;

		.header__bd {
			min-width: 820px;
			max-width: 980px;
			padding: 0 20px;
			margin: 0 auto;
			display: flex;
			justify-content: flex-start;

			.logo__panel {
				display: flex;
				align-items: center;

				.logo-box {
					display: flex;
					align-items: center;

					.logo {
						width: 50px;
						height: 50px;
						border-radius: 5px;
					}

					.title {
						margin-left: 20px;
						font-size: 24px;
						color: #fff;
					}
				}

				.city {
					margin-left: 20px;
					font-size: 15px;
					color: rgb(255, 161, 161);
				}
			}

			.menu {
				// margin-left: 40px;
				margin-left: 10px;
				flex: 1;
				background-color: transparent;

				&.ivu-menu-horizontal.ivu-menu-light:after {
					display: none;
				}

				.ivu-menu-item {
					color: white;
					font-size: 16px;
					padding: 0 10px;
					margin: 0 5px;
				}

				.ivu-menu-item-active,
				.ivu-menu-item:hover {
					color: #ff552e;
					border-bottom: 2px solid #c00;
				}
			}

			.account__panel {
				align-self: end;
				display: flex;
				align-items: center;

				.name-box {
					display: flex;
					align-items: center;
					padding: 0 5px;

					.avatar {
						width: 50px;
						height: 50px;
						border-radius: 5px;
					}

					.name-icon {
						color: #fff;
					}

					.name {
						// margin-left:2px;
						padding-left: 10px;
						font-size: 15px;
						color: #fff;
					}

					&:hover {
						position: relative;

						&::before {
							content: '';
							position: absolute;
							bottom: 1px;
							left: 0;
							width: 100%;
							height: 2px;
							background-color: #c00;
						}

						.name-icon {
							color: #ff552e;
						}

						.name {
							color: #ff552e;
						}
					}
				}
			}
		}
	}

	.main {
		height: 100%;

		.content-wrapper {
			position: relative;
			min-width: 820px;
			height: 100%;
			// height: ~"calc(100% - 64px)";
			overflow: auto;
			// overflow: auto scroll;
			margin-top: 64px;
			background: #f5f7f9;
		}

		.a-btn {
			font-size: 15px;
			color: #fff;

			&:hover {
				color: tint(#ccc, 20%);
			}
		}
	}

	.pendant {
		position: fixed;
		right: 20px;
		bottom: 170px;
		z-index: 1001;

		.item {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 70px;
			height: 70px;
			background-color: #4183c4;
			border-radius: 5px;
			cursor: pointer;

			img {
				width: 28px;
				height: 28px;
			}

			.label {
				margin-top: 5px;
				color: #fff;
			}
		}
	}

	.poptip__bd {
		padding: 5px 15px;
		font-size: 16px;
	}
</style>
