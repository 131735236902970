import Main from '@/components/Main.vue'

export default [{
    path: '/',
    component: Main,
    meta: {
      notCache: true
    },
    children: [{
      path: '/',
      name: 'home',
      meta: {
        title: '首页',
        notCache: true
      },
      component: () => import('@/views/Home.vue')
    }]
  }, {
    path: '/user',
    component: Main,
    meta: {
      notCache: true
    },
    children: [{
      path: '/user',
      name: 'user',
      meta: {
        title: '个人中心',
        notCache: true
      },
      component: () => import('@/views/User.vue')
    }]
  }, {
    path: '/data',
    component: Main,
    meta: {
      notCache: true
    },
    children: [{
      path: '/data',
      name: 'data',
      meta: {
        title: '共享资料',
        notCache: true
      },
      component: () => import('@/views/Data.vue')
    }, {
      path: '/data-detail',
      name: 'DataRecordDetail',
      meta: {
        title: '资料详情',
        notCache: true
      },
      component: () => import('@/views/DataRecordDetail.vue')
    }]
  }, {
    path: '/template',
    component: Main,
    meta: {
      notCache: true
    },
    children: [{
      path: '/template',
      name: 'template',
      meta: {
        title: '资料模版',
        notCache: true
      },
      component: () => import('@/views/Template.vue')
    }]
  },
  // {
  //   path: '/template/create',
  //   component: Main,
  //   meta: {
  //     notCache: true
  //   },
  //   children: [{
  //     path: '/template/create',
  //     name: 'temp-create',
  //     meta: {
  //       title: '填写模板',
  //       notCache: true
  //     },
  //     component: () => import('@/views/TempCreate.vue')
  //   }]
  // },
  {
    path: '/template/create',
    component: Main,
    meta: {
      notCache: true
    },
    children: [{
      path: '/template/create',
      name: 'temp-create',
      meta: {
        title: '填写模板',
        notCache: true
      },
      component: () => import('@/views/TempCreate3.vue')
    }]
  }, {
    path: '/edit-info',
    component: Main,
    meta: {
      notCache: true
    },
    children: [{
      path: '/edit-info',
      name: 'edit-info',
      meta: {
        title: '编辑信息',
        notCache: true
      },
      component: () => import('@/views/EditInfo.vue')
    }]
  }, {
    path: '/demand',
    component: Main,
    meta: {
      notCache: true
    },
    children: [{
      path: '/demand',
      name: 'demand',
      meta: {
        title: '供需信息',
        notCache: true
      },
      component: () => import('@/views/Demand.vue')
    }, {
      path: '/demand-detail',
      name: 'DemandDetail',
      meta: {
        title: '供需详情',
        notCache: true
      },
      component: () => import('@/views/DemandDetail.vue')
    }]
  }, {
    path: '/material',
    component: Main,
    meta: {
      notCache: true
    },
    children: [{
      path: '/material',
      name: 'material',
      meta: {
        title: '共享资料',
        notCache: true
      },
      component: () => import('@/views/Material.vue')
    }]
  }, {
    path: '/publicity',
    component: Main,
    meta: {
      notCache: true
    },
    children: [{
      path: '/publicity',
      name: 'publicity',
      meta: {
        title: '公示信息',
        notCache: true
      },
      component: () => import('@/views/Publicity.vue')
    }, {
      path: '/publicity-detail',
      name: 'PublicityDetail',
      meta: {
        title: '公示详情',
        notCache: true
      },
      component: () => import('@/views/PublicityDetail.vue')
    }]
  }, {
    path: '/changecity',
    component: Main,
    meta: {
      notCache: true
    },
    children: [{
      path: '/changecity',
      name: 'changecity',
      meta: {
        title: '切换城市',
        notCache: true
      },
      component: () => import('@/views/ChangeCity.vue')
    }]
  }, {
    path: '/member',
    component: Main,
    meta: {
      notCache: true
    },
    children: [{
      path: '/member',
      name: 'member',
      meta: {
        title: '会员',
        notCache: true
      },
      component: () => import('@/views/Member.vue')
    }]
  }, {
    path: '/contract',
    component: Main,
    meta: {
      notCache: true
    },
    children: [{
      path: '/contract/:id',
      name: 'contract',
      meta: {
        title: '资料详情'
      },
      component: () => import('@/views/Contract.vue')
    }]
  }, {
    path: '/401',
    name: 'error_401',
    meta: {
      hideInMenu: true
    },
    component: () => import('@/views/error-page/401.vue')
  }, {
    path: '*',
    name: 'error_404',
    meta: {
      hideInMenu: true
    },
    component: () => import('@/views/error-page/404.vue')
  }
]
