<template>
  <Modal class="dialog" v-model="pShown" title="登录" :mask-closable="false" scrollable :width="420">
    <div class="dialog-header" slot="header">
      <div class="title">注册{{ systemSetting && systemSetting.title ? systemSetting.title : '类众' }}会员</div>
    </div>
    <Form ref="form" :model="form" :rules="rules" :label-width="110">
      <FormItem prop="realName" label="姓名">
        <Input v-model="form.realName" placeholder="姓名" key="realName"></Input>
      </FormItem>
      <FormItem prop="mobile" label="手机号">
        <Input v-model="form.mobile" placeholder="手机号" key="mobile"></Input>
      </FormItem>
      <!-- <FormItem prop="code" label="验证码">
        <Input v-model="form.code" placeholder="验证码" key="code">
          <span slot="append">
            <a class="code-btn" @click="onGetCode">获取验证码</a>
          </span>
        </Input>
      </FormItem> -->
      <FormItem label="所在城市" required>
        <div class="layout-row">
          <Select v-model="selProvinceID" placeholder="省份">
            <Option v-for="item in provinces" :value="item.province_id" :key="item.province_id">{{ item.province_name }}</Option>
          </Select>
          <Select v-model="selCityID" placeholder="城市" style="margin-left:20px">
            <Option v-for="item in optCities" :value="item.city_id" :key="item.city_id">{{ item.city_name }}</Option>
          </Select>
        </div>
      </FormItem>
      <FormItem prop="industry" label="行业">
        <Input v-model="form.industry" placeholder="行业" key="industry"></Input>
      </FormItem>
      <!-- <FormItem class="has-tip" prop="userName" label="登录账号">
        <Input v-model="form.userName" placeholder="登录账号" key="userName"></Input>
        <span class="input-tip">登录账号：首字母开头的2-20个字符</span>
      </FormItem> -->
      <FormItem prop="inviter" label="推荐人手机号">
        <Input v-model="form.inviter" placeholder="推荐人手机号" key="inviter"></Input>
      </FormItem>
      <FormItem class="has-tip" prop="password" label="登录密码">
        <Input type="password" v-model="form.password" placeholder="登录密码" key="password"></Input>
        <span class="input-tip">密码：6个或6个以上字符</span>
      </FormItem>
      <FormItem prop="checkPasswd" label="确认密码">
        <Input type="password" v-model="form.checkPasswd" placeholder="确认密码" key="checkPasswd"></Input>
      </FormItem>
      <div class="login-box">
        <span>已有账号？</span>
        <a class="a-btn" @click="onLogin">去登录</a>
      </div>
    </Form>
    <div slot="footer">
      <Button type="primary" long :disabled="requesting" @click="onRegister" style="margin: 10px 0;">注 册</Button>
    </div>
  </Modal>
</template>

<script>

import { userRegister } from '@/api/user.js'
import { getRegionData } from '@/api/city.js'
import { mapMutations } from 'vuex'
import { getSystemSetting } from '@/api/setting'

export default {
  name: 'Register',
  model: {
    prop: 'shown',
    event: 'change'
  },
  props: {
    shown: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      requesting: false,

      pShown: false,
      form: {
        mobile: null,
        code: null,
        userName: null,
        password: null,
        checkPasswd: null,
        realName: null,
        inviter: null,
        industry:null,
      },
      provinces: null,
      selProvinceID: null,
      selCityID: null,

      systemSetting: null
    }
  },
  computed: {
    optCities() {
      if (this.selProvinceID != null) {
        const len = this.provinces.length
        for (let i = 0; i < len; i++) {
          if (this.selProvinceID == this.provinces[i].province_id) {
            return this.provinces[i].cities
          }
        }
      }
      return null
    },
    rules() {
      return {
        mobile: [{ required: true, message: '手机号不能为空', trigger: 'blur' }],
        code: [{ required: true, message: '验证码不能为空', trigger: 'blur' }],
        userName: [{ required: true, message: '登录账号不能为空', trigger: 'blur' }],
        password: [{ required: true, message: '登录密码不能为空', trigger: 'blur' }],
        realName: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
        inviter: [{ required: true, message: '推荐人手机号不能为空', trigger: 'blur' }],
        industry: [{ required: true, message: '行业不能为空', trigger: 'blur' }],
        checkPasswd: [{ required: true, validator: this.passwdValidator, trigger: 'blur' }]
      }
    }
  },
  watch: {
    pShown: function (newVal) {
      this.$emit('change', newVal)
      this.$Spin.show()
      if (newVal) {
        getRegionData().then(res => {
          this.provinces = res.content.provinces
        }).catch((err) => {
          this.$Message.error(err.message)
          this.$Spin.hide()
        })
      } else {
        setTimeout(() => {
          this.$refs.form.resetFields()
          this.showRegisterDialog(false)
          Object.assign(this.$data, this.$options.data.call(this))
        }, 300)
      }
      this.$Spin.hide()
    },
    shown: function (newVal) {
      this.pShown = newVal
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    ...mapMutations(['showRegisterDialog', 'showLoginDialog']),
    async initData() {
      await this.getSystemSetting()
    },
    async getSystemSetting() {
      const res = await getSystemSetting()
      this.systemSetting = res.content
    },
    passwdValidator(rule, value, callback) {
      if (value == null || value.length == 0) {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    },
    onGetCode() {

    },
    onLogin() {
      this.pShown = false
      this.showLoginDialog()
    },
    onRegister() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }

        if (this.selCityID == null) {
          this.$Message.error('请选择所在城市')
          return
        }

        const params = {
          // account: this.form.userName,
          inviter_mobile: this.form.inviter,
          industry:this.form.industry,
          password: this.form.password,
          mobile: this.form.mobile,
          code: this.form.code,
          real_name: this.form.realName,
          city_id: this.selCityID
        }
        this.requesting = true

        try {
          const res = await userRegister(params)
          // 保存用户信息
          this.$store.commit('setToken', res.content.user_token)
          this.$store.commit('setUserInfo', res.content.user_info)

          this.$Message.success('注册成功')
          this.pShown = false

          // 跳转会员充值页
          this.$router.push({name: 'member', params: {level: 0}})
        } catch (err) {
          this.$Message.error(err.message)
        }

        this.requesting = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.dialog {
  ::v-deep .ivu-modal-header {
    border-bottom: none;
    padding: 14px 0;
  }
  ::v-deep .ivu-modal-body {
    padding: 16px 30px 16px 16px;
  }
  ::v-deep .ivu-modal-footer {
    padding: 12px 40px;
  }
  ::v-deep .ivu-input {
    height: 40px;
    padding: 10px;
  }
  .has-tip ::v-deep .ivu-form-item-error-tip {
    padding: 0 0 6px;
  }
}

.dialog-header {
  .title {
    padding: 10px;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
    color: #17233d;
    padding: 10px 0;
  }
  ::v-deep .ivu-menu {
    display: flex;
    justify-content: center;
  }
}

.input-tip {
  font-size: 14px;
  line-height: 1;
  color: #999;
}

.code-btn {
  display: block;
  font-size: 14px;
  color: #4183c4;
  &:hover{
    filter: opacity(0.6);
  }
}

.login-box {
  width: fit-content;
  margin-left: auto;
  .a-btn {
    display: inline-block;
    font-size: 14px;
    color: #4183c4;
    width: fit-content;
    &:hover {
      filter: opacity(0.6);
    }
  }
}
</style>
